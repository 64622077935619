@use "@angular/material" as mat;
@use "themes/theme" as theme;

.cdk-overlay-container {
  .mat-snack-bar-container {
    &.danger-snack {
      @include theme.get-theme-color-property(background, warn);
      @include theme.get-theme-color-property(color, warn, '700-contrast');

      span {
        @include theme.get-theme-color-property(color, warn, '700-contrast');

      }

      button {
        @include theme.get-theme-color-property(color, warn, '700-contrast');
      }
    }

    &.success-snack {
      background: mat.get-color-from-palette(mat.$green-palette, 700);

      span {
        color: #fff;
      }

      button {
        color: #fff;
      }
    }

    &.info-snack {
      @include theme.get-theme-color-property(background, primary);

      span {
        @include theme.get-theme-color-property(color, primary, '700-contrast');
      }

      button {
        @include theme.get-theme-color-property(color, primary, '700-contrast');
      }
    }
  }
}
