@use 'themes/theme' as theme;

.mat-card {
  .mat-card-header {
    .mat-card-header-text {
      margin: 0 2px;
    }
  }
}

.statistics-summary-card {
  padding: 0 !important;
  overflow: hidden;

  .mat-card-header {
    @include theme.get-theme-color-property(background-color, primary, 100);
    @include theme.get-theme-color-property(color, primary, '100-contrast');
    padding: 1rem;
  }

  .mat-card-title {
    margin-bottom: 0 !important;
    font-size: 1rem;
  }

  .mat-card-content {
    padding: 1rem;
  }

  .number-big {
    font-weight: bold;
    font-size: 3rem;
    line-height: 3rem;
    @include theme.get-theme-color-property(color, primary);
  }

}
