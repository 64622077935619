.accordion-filter {
  &.mat-accordion {

    .mat-expansion-panel {
      box-shadow: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      .mat-expansion-panel-body {
        padding-top: 1rem;
      }

      .mat-expansion-panel-header-description {
        justify-content: end;
        align-items: center;
      }
    }

    mat-panel-title {
      flex-grow: 1;
    }

    mat-panel-description {
      flex-grow: 0;
    }
  }
}
