@use "@angular/material" as mat;
@use 'themes/theme' as theme;

// Set custom autofill inputs to have no background and red text.
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px mat.get-color-from-palette(mat.$light-theme-background-palette, 'card') inset !important;
  -webkit-text-fill-color: mat.get-color-from-palette(mat.$light-theme-foreground-palette, 'base') !important;
}

.dark-theme {
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px mat.get-color-from-palette(mat.$dark-theme-background-palette, 'card') inset !important;
    -webkit-text-fill-color: mat.get-color-from-palette(mat.$dark-theme-foreground-palette, 'base') !important;
  }
}

.multi-select-field {
  line-height: 1.25rem;

  mat-chip {
    font-size: .75rem;
    min-height: 25px;
  }
}

.option-create {
  font-style: italic;
  padding-left: 1rem;

  &:before {
    font-family: "Material Icons";
    content: "\e145";
    margin-right: .5rem;
  }
}

.form-field-readonly {
  >.mat-form-field-wrapper {
    >.mat-form-field-flex {

      .mat-input-element,
      .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
        color: inherit;
      }
    }
  }

}

.checkbox-w-full {
  width: 100%;

  .mat-checkbox-layout {
    width: 100%;

    .mat-checkbox-label {
      width: 100%;
    }
  }
}
