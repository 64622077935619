@use "@angular/material" as mat;
@use 'themes/theme' as theme;
@import 'variables';

.mat-icon-button.delete-button {
  @include theme.get-theme-color-property(color, warn);
}

.dark-theme .mat-icon-button.delete-button {
  @include theme.get-theme-color-property(color, warn, 300);
}

button.mat-button .mat-icon,
button.mat-raised-button .mat-icon,
button.mat-flat-button .mat-icon,
button.mat-stroked-button .mat-icon,
a.mat-button .mat-icon,
a.mat-raised-button .mat-icon,
a.mat-flat-button .mat-icon,
a.mat-stroked-button .mat-icon {
  vertical-align: top;
  font-size: 1.25rem;
}

.raised-icon-button {
  background: mat.get-color-from-palette(mat.$dark-theme-foreground-palette, 'base');
  box-shadow: $mat-elevation-z3;

  .dark-theme & {
    background: mat.get-color-from-palette(mat.$light-theme-foreground-palette, 'base');
    box-shadow: $mat-elevation-z3;
  }

  &.btn-add {
    @include theme.get-theme-color-property(background, accent);
    @include theme.get-theme-color-property(color, accent, '500-contrast');
  }
}
