/* ----- THEMING ----- */
@use "@angular/material" as mat;
@use "themes/light" as light;
@use "themes/typography" as typography;
@use "@angular/cdk";

@include mat.core(typography.$polar-typography);

@include mat.core-theme(light.$polar-light-theme);
@include mat.all-component-themes(light.$polar-light-theme);

/* ----- TAILWIND ----- */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ----- CUSTOM STYLES ----- */
@import "variables", "mixins", "globals";
@import "components/tables", "components/sidenav", "components/forms",
"components/snack", "components/buttons", "components/toolbars",
"components/upload", "components/modals", "components/big-calendar",
"components/accordions", "components/cards", "components/alerts";
