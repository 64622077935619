$mat-elevation-z3: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
$mat-elevation-z2: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
$mat-elevation-z1: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
$mat-elevation-z10: 0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%);

$mat-card-shadow: $mat-elevation-z1;
$mat-card-border-radius: 4px;

// big calendar
$bcBorderColor: rgba(0, 0, 0, 0.2); // #ccc;
$bcWeekdayLabelColor: rgba(0, 0, 0, 0.75); // #777;
$bcNumberColor: rgba(0, 0, 0, 0.75); // #777;
$bcMonthBackgroundColor: rgba(0, 0, 0, 0); // #eee;
$bcMonthLabelColor: rgba(0, 0, 0, 0.75); // #777;
$bcBackgroundColor: rgba(0, 0, 0, 0); // #fff;
$bcWeekNumberBackgroundColor: rgba(0, 0, 0, 0); // #ccc;
$bcWeekNumberWidth: 30px;
$bcColHeaderMinHeight: 40px;
$bcColMinHeight: 100px;
$bcOddMonthDayColor: rgba(0, 0, 0, 0.075);
//dark
$bcBorderColorDarkTheme: rgba(255, 255, 255, 0.2); // #ccc;
$bcWeekdayLabelColorDarkTheme: rgba(255, 255, 255, 0.75); // #777;
$bcNumberColorDarkTheme: rgba(255, 255, 255, 0.75); // #777;
$bcMonthBackgroundColorDarkTheme: rgba(255, 255, 255, 0); // #eee;
$bcMonthLabelColorDarkTheme: rgba(255, 255, 255, 0.75); // #777;
$bcBackgroundColorDarkTheme: rgba(255, 255, 255, 0); // #fff;
$bcWeekNumberBackgroundColorDarkTheme: rgba(255, 255, 255, 0); // #ccc;
$bcOddMonthDayColorDarkTheme: rgba(255, 255, 255, 0.075);
