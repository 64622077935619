@use 'themes/theme' as theme;

.upload-field {
  width: 100%;

  mat-toolbar {
    position: relative;
    font-size: 1rem;
    @include theme.get-theme-color-property(color, primary);
  }

  mat-divider {
    height: 75%;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  button {
    // width: 7rem;
  }

  #fileInput {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    opacity: 0;
    height: 100%;
    left: 0px;
    top: 0px;
    width: 100%;

    &.clear-visible {
      width: calc(100% - 4.5rem);
    }
  }
}
