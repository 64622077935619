@use "@angular/material" as mat;
@use "themes/theme" as theme;

.mat-card .alert {
  box-shadow: none;
  &--danger {
    @include theme.get-theme-color-property(background, warn);
    @include theme.get-theme-color-property(color, warn, '700-contrast');
  }

  &--success {
    background: mat.get-color-from-palette(mat.$green-palette, 700);
    color: #fff;
  }

  &--info {
    @include theme.get-theme-color-property(background, primary);
    @include theme.get-theme-color-property(color, primary, '700-contrast');
  }
}
