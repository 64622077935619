@import 'variables';

.toolbar-form-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    box-shadow: $mat-elevation-z10;

    .actions {
        margin-left: auto;

        button {
            margin-left: .5rem;
        }
    }
}